import { IonButton } from "@ionic/react";
import { Fragment, useEffect, useState, memo } from "react";
import { IonActionSheet } from "@ionic/react";
import { useSelector } from "react-redux";

import { fN } from "../../functions/baseFunctions";
import { editChildData } from "../../functions/funcFirebase";
import StockSymbolChart from "./StockSymbolChart";

const StockInfo = memo(
  ({ privacyMode, item, token, remoteConfig, trades, transactions, refresh, stockGroups, setHardRefresh, setShowAddNote, setSymbolToAdd, setShowAddWatchList }) => {
    const userID = useSelector((state) => state.user.uid);
    const [showAddStockToGroup, setShowAddStockToGroup] = useState(false);
    const [buttonItems, setButtonItems] = useState([]);

    useEffect(() => {
      // setActiveItem(symbol);
      stockGroups &&
        Object.entries(stockGroups).length > 0 &&
        Object.entries(stockGroups).forEach((item) => {
          let theKey = item[0];
          let theData = item[1];
          setButtonItems((buttonItems) => [
            ...buttonItems,
            {
              text: theData.name,
              handler: () => {
                editChildData(userID, `stockGroup/${theKey}/symbols`, `${theData.symbols},${item.symbol}`);
                setHardRefresh(true);
              },
            },
          ]);
        });
    }, []);

    return (
      <Fragment>
        <IonActionSheet isOpen={showAddStockToGroup} onDidDismiss={() => setShowAddStockToGroup(false)} buttons={buttonItems}></IonActionSheet>
        <tr style={{ justifyContent: "center" }}>
          <td colSpan={15}>
            <div className="stock-info-company">{item.company}</div>
            <div className="stock-info-add-to-wrapper">
              <span
                className="stock-info-add-to-group-button"
                onClick={() => {
                  setShowAddStockToGroup(true);
                }}
              >
                + Group
              </span>
              <span
                className="stock-info-add-to-group-button"
                onClick={() => {
                  setSymbolToAdd(item.symbol);
                  setShowAddWatchList(true);
                }}
              >
                + Watch
              </span>
              <span
                className="stock-info-add-to-group-button"
                // onClick={() => handleStockNotes()}
                onClick={() => {
                  setSymbolToAdd(item.symbol);
                  setShowAddNote(true);
                }}
              >
                + Note
              </span>
            </div>
            <div className="stock-info-wrapper">
              <div className="stock-info-separator">
                <div className="stock-high-low-wrapper">
                  <div className="stock-info-label">Av.Cost:</div>
                  <div className="stock-info-value">{privacyMode ? "***" : item.avgPrice.toFixed(2)}</div>
                  <div className="stock-info-label">Invested:</div>
                  <div className="stock-info-value">{privacyMode ? "***" : fN(Math.floor(item.avgPrice * item.position))}</div>
                  <div className="stock-info-label">M.Value:</div>
                  <div className="stock-info-value">{privacyMode ? "***" : fN(Math.floor(item.last * item.position))}</div>

                  <div className="stock-info-label">Unrlz.:</div>
                  <div className="stock-info-value">
                    <span style={item.unrealized_pnl >= 0 ? { color: "green" } : { color: "red" }}>{privacyMode ? "***" : fN(Math.floor(item.unrealized_pnl))}</span>
                  </div>
                </div>
              </div>

              <div className="stock-info-separator">
                <div>
                  <div className="stock-info-type">{item.type}</div>
                  <div className="stock-info-roi">
                    <span style={item.roi >= 0 ? { color: "green" } : { color: "red" }}>{privacyMode ? "***" : item.roi}%</span>
                  </div>
                </div>
              </div>

              <div>
                <div className="stock-high-low-wrapper">
                  <div className="stock-info-label">Year H:</div>
                  <div className="stock-info-value">{item.week_52_high.toFixed(2)}</div>
                  <div className="stock-info-label">Year L:</div>
                  <div className="stock-info-value">{item.week_52_low.toFixed(2)}</div>

                  <div className="stock-info-label">Day H:</div>
                  <div className="stock-info-value">{item.high.toFixed(2)}</div>
                  <div className="stock-info-label">Day L:</div>
                  <div className="stock-info-value">{item.low.toFixed(2)}</div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <IonButton routerLink={`/stocks/details?stock=${item.symbol}`} fill="clear" style={{ color: "red" }}>
                More Details
              </IonButton>
            </div>
            <div>
              <StockSymbolChart
                symbol={item.symbol}
                avgPrice={item.avgPrice}
                last={item.last}
                token={token}
                remoteConfig={remoteConfig}
                trades={trades}
                privacyMode={privacyMode}
                refresh={refresh}
              />
            </div>
          </td>
        </tr>
      </Fragment>
    );
  }
);

export default StockInfo;
