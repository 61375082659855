export const stocksSettings = {
  mode: "compact",
  last: true,
  change: true,
  changePercent: true,
  position: true,
  pnl: true,
  market: true,
  cashReport: true,
  watchList: true,
  indices: true,
  searchInput: false,
  topGainersLosers: true,
  earnPanel: true,
  divPanel: false,
  trades: false,
  stocksPercentage: false,
  avgPrice: false,
  averageAnalystRating: false,
  unrealized_pnl: true,
  unrealized_percent: false,
  marketValue: false,
  percentOfNAV: false,
  dividendPercent: false,
  dividendTotal: false,
  dividendDate: false,
  forwardPE: false,
};

export const tradesCount = 1000;
export const transactionsCount = 1000;
