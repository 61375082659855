import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { getStocksNAV } from "../../functions/stockFunctions";

const StocksPercentage = ({ data, topN }) => {
  const [ser, setSer] = useState([]);
  const [lab, setLab] = useState([]);
  const [topNum, setTopNum] = useState(5);

  useEffect(() => {
    const getStocksNAVData = getStocksNAV(data, topNum);
    setSer(getStocksNAVData.series);
    setLab(getStocksNAVData.labels);
  }, [data, topNum]);
  var options = {
    legend: {
      show: true,
      position: "right",
      offsetX: 0,
      offsetY: -2,
    },
    grid: {
      padding: {
        top: -2,
        bottom: -2,
      },
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
        offsetX: 0,
        offsetY: 0,
        customScale: 1,
        dataLabels: {
          offset: -10,
          minAngleToShowLabel: 20,
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: lab,
    colors: ["#e52f2f", "#7d84fa", "#344484", "#7a7fb6", "#c4a4d4", "#24242f"],
  };

  return (
    <div className="home-secondary-block">
      <div style={{ display: "flex", justifyContent: "center", marginTop: "15px", marginBottom: "15px" }}>
        <span className="stock-top-stock-percent-selector" onClick={() => setTopNum(2)}>
          {" "}
          Top 2{" "}
        </span>
        |{" "}
        <span className="stock-top-stock-percent-selector" onClick={() => setTopNum(5)}>
          {" "}
          Top 5{" "}
        </span>
        |{" "}
        <span className="stock-top-stock-percent-selector" onClick={() => setTopNum(10)}>
          Top 10
        </span>
        |
        <span className="stock-top-stock-percent-selector" onClick={() => setTopNum(15)}>
          Top 15
        </span>
      </div>

      <div style={{ margin: "0px", padding: "0px", justifyContent: "center", display: "flex", zindex: 2 }}>
        <Chart options={options} series={ser} type="pie" width="380" height="350" style={{ zindex: 2 }} />
      </div>

      <p />
    </div>
  );
};

export default StocksPercentage;
