import { IonRow, IonCol } from "@ionic/react";
import { Fragment, useState } from "react";

import StockGroupSetter from "../stocks/StockGroupSetter";
import StockGainersLosers from "./StockGainersLosers";
import StockAddWatchList from "./StockAddWatchList";
import StockSettings from "../stocks/StockSettings";
import StocksTableHead from "./StocksTableHead";
import StocksTableMode from "./StocksTableMode";
import StockTableBody from "./StocksTableBody";
import StockAddNote from "./StockAddNote";
import StockInfo from "./StockInfo";

const StocksTable = ({
  userID,
  liveStockData,
  privacyMode,
  stockSortedItem,
  setStockSortedItem,
  filteredStock,
  setFilteredStock,
  stockSettings,
  setStockSettings,
  remoteConfig,
  trades,
  activeStockAccount,
  setFilteredGroupStock,
  stockGroups,
  activeStock,
  setActiveStock,
  setActiveGroup,
  setHardRefresh,
}) => {
  const [sortOperator, setSortOperator] = useState(true);
  const [stockPanelOpen, setStockPanelOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showAddNote, setShowAddNote] = useState(false);
  const [showAddWatchList, setShowAddWatchList] = useState(false);
  const [symbolToAdd, setSymbolToAdd] = useState("");
  const [stockSettingsPanel, setStockSettingsPanel] = useState(false);

  const panelOpenHandler = (stock) => {
    setActiveStock(stock);
    setStockPanelOpen(!stockPanelOpen);
  };

  const sortHandler = (stock) => {
    setStockSortedItem(stock);
    setSortOperator(!sortOperator);
  };

  switch (stockSortedItem) {
    default:
      sortOperator
        ? liveStockData.sort((a, b) => (a[stockSortedItem] > b[stockSortedItem] ? 1 : -1))
        : liveStockData.sort((a, b) => (a[stockSortedItem] < b[stockSortedItem] ? 1 : -1));
  }

  return (
    <IonRow>
      <IonCol size="12">
        {activeStockAccount.type === "IBKR" && stockSettings.topGainersLosers && (
          <StockGainersLosers
            data={liveStockData}
            privacyMode={privacyMode}
            filteredStock={filteredStock}
            setFilteredStock={setFilteredStock}
            setStockPanelOpen={setStockPanelOpen}
            setActiveStock={setActiveStock}
            setRefresh={setRefresh}
          />
        )}
        <div className="stock-table-panel">
          <StockGroupSetter
            stockGroups={stockGroups}
            setFilteredGroupStock={setFilteredGroupStock}
            setActiveGroup={setActiveGroup}
            stockSettingsPanel={stockSettingsPanel}
            setStockSettingsPanel={setStockSettingsPanel}
            setFilteredStock={setFilteredStock}
          />

          <StocksTableMode
            stockSettings={stockSettings}
            setStockSettings={setStockSettings}
            stockSettingsPanel={stockSettingsPanel}
            setStockSettingsPanel={setStockSettingsPanel}
          />
        </div>
        {stockSettingsPanel && <StockSettings stockSettings={stockSettings} setStockSettings={setStockSettings} />}

        {showAddNote && <StockAddNote userID={userID} showAddNote={showAddNote} setShowAddNote={setShowAddNote} symbolToAdd={symbolToAdd} />}

        {showAddWatchList && <StockAddWatchList userID={userID} showAddWatchList={showAddWatchList} setShowAddWatchList={setShowAddWatchList} symbolToAdd={symbolToAdd} />}

        <div className="stock-main-table-wrapper">
          {activeStockAccount.type === "IBKR" && (
            <table className="stock-main-table">
              <StocksTableHead stockSortedItem={stockSortedItem} sortHandler={sortHandler} leng={liveStockData?.length} stockSettings={stockSettings} />

              <tbody>
                {liveStockData &&
                  liveStockData
                    .filter((val) => {
                      if (filteredStock === "") {
                        return val;
                      } else if (val.symbol.includes(filteredStock)) {
                        return val;
                      }
                    })
                    .map((item, index) => (
                      <Fragment key={index}>
                        <StockTableBody item={item} panelOpenHandler={panelOpenHandler} stockSettings={stockSettings} privacyMode={privacyMode} />

                        {activeStock === item.symbol && stockPanelOpen && (
                          <StockInfo
                            privacyMode={privacyMode}
                            item={item}
                            token={userID}
                            remoteConfig={remoteConfig}
                            trades={trades}
                            refresh={refresh}
                            activeStock={activeStock}
                            stockGroups={stockGroups}
                            setHardRefresh={setHardRefresh}
                            showAddNote={showAddNote}
                            setShowAddNote={setShowAddNote}
                            setSymbolToAdd={setSymbolToAdd}
                            setShowAddWatchList={setShowAddWatchList}
                          />
                        )}
                      </Fragment>
                    ))}
              </tbody>
            </table>
          )}
        </div>
      </IonCol>
    </IonRow>
  );
};

export default StocksTable;
