import { IonIcon, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonButton, IonList } from "@ionic/react";
import { chevronDownCircleOutline, chevronForwardCircleOutline, closeOutline, createOutline, trashOutline } from "ionicons/icons";

import { fN } from "../../functions/baseFunctions";

const FireListEditItems = ({
  showFireItems,
  theData,
  handleOpen,
  fireKey,
  itemOpen,
  activeFireKey,
  activeList,
  form,
  handleChange,
  present,
  deleteItem,
  editItem,
  setItemOpen,
}) => {
  return (
    showFireItems &&
    fireKey === activeFireKey &&
    theData.items &&
    Object.entries(theData.items).map((item) => {
      let itemsKey = item[0];
      let itemsData = item[1];
      return (
        <IonList key={itemsKey}>
          <IonItem>
            <IonLabel onClick={() => handleOpen(fireKey, itemsKey)}>
              <IonIcon
                style={{ fontSize: "18px", marginBottom: -3, marginRight: "3px" }}
                icon={itemOpen && activeFireKey === fireKey && itemsKey === activeList ? chevronDownCircleOutline : chevronForwardCircleOutline}
              />
              <strong>{itemsData.item}</strong>
              <p>{itemsData.desc}</p>
            </IonLabel>
            <span style={{ fontSize: "18px" }}>
              <b>${fN(Math.floor(itemsData.amount))}</b>/Y
              <br />
              <span
                style={
                  itemsData.type === "spending"
                    ? { fontSize: "10px", color: "red", fontWeight: 600, float: "right" }
                    : { fontSize: "10px", color: "green", fontWeight: 600, float: "right" }
                }
              >
                {itemsData.type === "spending" ? "SPENDING" : "INCOME"}
              </span>
            </span>
            <br />
          </IonItem>

          {itemOpen && activeFireKey === fireKey && activeList === itemsKey && (
            <>
              <IonItem>
                <IonLabel style={{ fontSize: "12px" }}>
                  <IonIcon icon={createOutline} /> Item:{" "}
                </IonLabel>
                <IonInput
                  style={{ fontSize: "12px", color: "green", fontWeight: 600 }}
                  name="item"
                  value={form["item"] || ""}
                  placeholder="Enter Input"
                  onIonChange={handleChange}
                ></IonInput>
              </IonItem>
              <IonItem style={{ fontSize: "12px" }}>
                <IonLabel>
                  <IonIcon icon={createOutline} /> Amount:{" "}
                </IonLabel>
                <IonInput
                  style={{ fontSize: "12px", color: "green", fontWeight: 600 }}
                  placeholder="Enter Input"
                  name="amount"
                  value={form["amount"]}
                  onIonChange={handleChange}
                  type="number"
                ></IonInput>
              </IonItem>
              <IonItem style={{ fontSize: "12px" }}>
                <IonLabel>
                  <IonIcon icon={createOutline} /> Duration:{" "}
                </IonLabel>
                <IonInput
                  style={{ fontSize: "12px", color: "green", fontWeight: 600 }}
                  placeholder="Enter Input"
                  name="duration"
                  value={form["duration"]}
                  type="number"
                  onIonChange={handleChange}
                ></IonInput>
              </IonItem>
              <IonItem style={{ fontSize: "12px" }}>
                <IonLabel>
                  <IonIcon icon={createOutline} /> Start Year:{" "}
                </IonLabel>
                <IonInput
                  style={{ fontSize: "12px", color: "green", fontWeight: 600 }}
                  placeholder="Enter Input"
                  name="startYear"
                  value={form["startYear"]}
                  type="number"
                  onIonChange={handleChange}
                ></IonInput>
              </IonItem>
              <IonItem style={{ fontSize: "12px" }}>
                <IonLabel>
                  <IonIcon icon={createOutline} />
                  Desc.:{" "}
                </IonLabel>
                <IonInput
                  style={{ fontSize: "12px", color: "green", fontWeight: 600 }}
                  name="desc"
                  value={form["desc"] || ""}
                  placeholder="Enter Input"
                  onIonChange={handleChange}
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel>Type</IonLabel>
                <IonSelect name="type" value={form["type"] || ""} placeholder="spending" okText="Okay" cancelText="Dismiss" onIonChange={handleChange} required>
                  <IonSelectOption value="spending">Spending</IonSelectOption>
                  <IonSelectOption value="income">Income</IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel>Increased with Inflation</IonLabel>
                <IonSelect name="inflation" value={form["inflation"] || ""} placeholder="yes" okText="Okay" cancelText="Dismiss" onIonChange={handleChange} required>
                  <IonSelectOption value="yes">yes</IonSelectOption>
                  <IonSelectOption value="no">no</IonSelectOption>
                </IonSelect>
              </IonItem>
              <div style={{ textAlign: "center" }}>
                <IonButton
                  size="small"
                  color="danger"
                  onClick={() =>
                    present({
                      cssClass: "my-css",
                      header: "Delete",
                      message: "Are you sure you want to delete this item?",
                      buttons: ["Cancel", { text: "Delete", handler: (d) => deleteItem(fireKey, itemsKey) }],
                    })
                  }
                >
                  <IonIcon icon={trashOutline} />
                  Delete
                </IonButton>
                <IonButton size="small" onClick={() => editItem(fireKey, itemsKey)}>
                  Save
                </IonButton>
                <IonButton size="small" onClick={() => setItemOpen(!itemOpen)}>
                  <IonIcon icon={closeOutline} />
                  Close
                </IonButton>
              </div>
            </>
          )}
        </IonList>
      );
    })
  );
};

export default FireListEditItems;
